/** Reusable component names for Uno leftNav app */ export var ComponentNames;
(function(ComponentNames) {
    /** Component name for Uno leftNav container */ ComponentNames["UnoLeftNavContainer"] = "UnoLeftNavContainer";
    /** Component name for Uno leftNav page */ ComponentNames["UnoLeftNav"] = "UnoLeftNav";
    /** Component name for Uno leftNav static section */ ComponentNames["UnoLeftNavStaticSection"] = "UnoLeftNavStaticSection";
    /** Component name for Uno leftNav pinned section */ ComponentNames["UnoLeftNavPinnedSection"] = "UnoLeftNavPinnedSection";
    /** Component name for Uno leftNav new plan section */ ComponentNames["UnoLeftNavNewPlanSection"] = "UnoLeftNavNewPlanSection";
    /** Component name for Uno leftNav group */ ComponentNames["UnoLeftNavGroup"] = "UnoLeftNavGroup";
    /** Component name for Uno leftNav option */ ComponentNames["UnoLeftNavOption"] = "UnoLeftNavOption";
})(ComponentNames || (ComponentNames = {}));
