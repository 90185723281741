// Utilities
import { mergeStyles } from "@fluentui/style-utilities";
import { memoizeFunction } from "@fluentui/utilities";
var GroupTileColors;
/*
    Color palette from Groups
    Used to color dynamic tiles
*/ (function(GroupTileColors) {
    GroupTileColors["color0"] = "rgb(104, 123, 33)";
    GroupTileColors["color1"] = "rgb(76, 119, 168)";
    GroupTileColors["color2"] = "rgb(177, 87, 144)";
    GroupTileColors["color3"] = "rgb(0, 163, 0)";
    GroupTileColors["color4"] = "rgb(30, 113, 69)";
    GroupTileColors["color5"] = "rgb(217, 0, 128)";
    GroupTileColors["color6"] = "rgb(126, 56, 120)";
    GroupTileColors["color7"] = "rgb(96, 60, 186)";
    GroupTileColors["color8"] = "rgb(0, 130, 129)";
    GroupTileColors["color9"] = "rgb(0, 120, 212)";
    GroupTileColors["color10"] = "rgb(43, 87, 151)";
    GroupTileColors["color11"] = "rgb(196, 74, 38)";
    GroupTileColors["color12"] = "rgb(185, 29, 71)";
    GroupTileColors["color13"] = "rgb(226, 16, 16)";
})(GroupTileColors || (GroupTileColors = {}));
/**
 * Get classname for the background color of a group tile
 * @param tileClassName Classname for the group tile
 */ export const getGroupColorClassName = memoizeFunction((tileClassName)=>{
    if (GroupTileColors[tileClassName] == null) {
        return "";
    }
    const style = {
        backgroundColor: GroupTileColors[tileClassName]
    };
    return mergeStyles(style);
});
