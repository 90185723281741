// Utilities
import { memoizeFunction, mergeStyles, FontSizes, FontWeights } from "@fluentui/react";
import { flexChildSizeFixed, flexChildSizeFlexible, flexParent, flexParentCenterAll, textClip } from "@ms/uno-controls/lib/local/utilities/Mixin.styles";
// Constants
import { rowEdgeToContentSpacing, rowIconTextSpacing } from "./UnoLeftNav.styles";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
import { highContrastBackground, highContrastHighlightBorders, highContrastHighlightColor, highContrastHighlightTextColor, highContrastSelectionText } from "@ms/uno-controls/lib/local/utilities/HighContrast.styles";
/**
 * Uno Left Nav option style constants
 */ const rowHeight = "44px";
const linkIconWidth = "28px";
const borderRadius = "5px";
const dynamicIconBorderRadius = "4px";
/**
 * Helper to get styles for the Left Nav Option
 * @param isSelected Indicates if option is selected
 * @param shouldShowIcon Indicates if the icon is visible
 * @param theme Current app Fluent v8 theme
 */ export const getUnoLeftNavOptionStyles = memoizeFunction(({ breadCrumbToDisplay, theme })=>{
    const rowEdgeToLinkItemSpacing = "8px";
    const linkContentRowHeight = breadCrumbToDisplay ? "36px" : "22px";
    const leftNavOptionHoverFocusStyles = {
        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
        ".contextMenuButton": {
            opacity: "1",
            color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextHovered : theme.palette.themePrimary
        },
        ".staticIcon": {
            color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextHovered : theme.palette.themePrimary
        },
        ".leftNavItem": [
            {
                color: theme.semanticColors.buttonTextHovered
            },
            highContrastHighlightColor()
        ],
        ".simpleBreadcrumb": {
            ".item, .chevron": {
                color: theme.semanticColors.buttonTextHovered
            }
        }
    };
    const leftNavOptionStyles = {
        root: [
            flexParent(),
            flexChildSizeFixed(rowHeight),
            {
                alignItems: "center",
                cursor: "pointer",
                overflow: "hidden",
                borderRadius: borderRadius,
                margin: "1px 8px 1px 8px",
                boxSizing: "content-box",
                "> a": [
                    flexParent(),
                    flexChildSizeFlexible("auto"),
                    {
                        padding: `0px ${rowEdgeToContentSpacing} `,
                        alignItems: "center",
                        height: `${rowHeight} `
                    }
                ],
                "&:hover, &:focus": leftNavOptionHoverFocusStyles,
                "&:active": {
                    backgroundColor: theme.semanticColors.buttonBackgroundPressed,
                    ".contextMenuButton": {
                        opacity: "1",
                        color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextPressed : theme.palette.themePrimary
                    },
                    ".staticIcon": {
                        color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextPressed : theme.palette.themePrimary
                    },
                    ".leftNavItem": {
                        color: theme.semanticColors.buttonTextPressed
                    },
                    ".simpleBreadcrumb": {
                        ".item, .chevron": {
                            color: theme.semanticColors.buttonTextPressed
                        }
                    }
                },
                "&.is-selected": [
                    {
                        backgroundColor: theme.semanticColors.buttonBackgroundChecked,
                        ".linkContents": {
                            position: "relative",
                            borderRadius: "2px",
                            "::before": [
                                highContrastBackground(),
                                {
                                    content: '""',
                                    position: "absolute",
                                    top: "50%",
                                    left: 0,
                                    height: "22px",
                                    width: "3px",
                                    backgroundColor: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextChecked : theme.palette.themePrimary,
                                    transform: "translateY(-50%)",
                                    borderRadius: "2px",
                                    boxSizing: "border-box"
                                }
                            ]
                        },
                        ".contextMenuButton": {
                            opacity: "1",
                            color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextChecked : theme.palette.themePrimary
                        },
                        ".staticIcon": {
                            color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonTextChecked : theme.palette.themePrimary,
                            ".plannerIcon": [
                                highContrastHighlightBorders(),
                                {
                                    svg: highContrastHighlightTextColor()
                                }
                            ]
                        },
                        ".leftNavItem": [
                            {
                                color: theme.semanticColors.buttonTextChecked
                            },
                            highContrastHighlightTextColor()
                        ],
                        ".simpleBreadcrumb": {
                            ".item, .chevron": {
                                color: theme.semanticColors.buttonTextChecked
                            }
                        }
                    },
                    highContrastSelectionText()
                ],
                "&:not(.is-selected)&.is-expanded": leftNavOptionHoverFocusStyles
            }
        ],
        linkContents: [
            flexParent("row"),
            flexChildSizeFlexible("auto"),
            {
                alignItems: "center",
                minWidth: "0",
                marginBlockStart: "6px",
                marginBlockEnd: "6px",
                overflow: "visible",
                height: linkContentRowHeight
            }
        ],
        staticIcon: [
            flexParent(),
            flexChildSizeFixed(linkIconWidth),
            flexParentCenterAll,
            {
                color: theme.semanticColors.buttonText,
                marginInlineStart: rowEdgeToLinkItemSpacing,
                fontSize: FontSizes.size20
            }
        ],
        dynamicIcon: [
            flexParent(),
            flexChildSizeFixed(linkIconWidth),
            flexParentCenterAll,
            {
                color: theme.palette.white,
                marginInlineStart: rowEdgeToLinkItemSpacing,
                fontSize: FontSizes.size20,
                borderRadius: dynamicIconBorderRadius,
                "&.text-wrapper": {
                    height: "28px",
                    width: "28px"
                }
            }
        ],
        text: [
            flexParent("column"),
            flexChildSizeFlexible("auto"),
            {
                marginInlineStart: rowIconTextSpacing,
                fontStyle: "normal",
                fontSize: FontSizes.size14,
                fontWeight: FontWeights.regular,
                "> .leftNavItem": [
                    textClip("ellipsis"),
                    {
                        color: theme.palette.neutralPrimary
                    }
                ]
            }
        ],
        contextMenuButton: [
            {
                opacity: "0",
                backgroundColor: "inherit",
                color: UnoTheme.TeamsContrast === theme.name ? theme.semanticColors.buttonText : theme.palette.themePrimary,
                "&:hover, &:focus, &:focus-within, &:focus-visible": {
                    backgroundColor: "inherit",
                    opacity: "1"
                }
            }
        ]
    };
    return leftNavOptionStyles;
});
/**
 * Get the classnames associated with the computed styles for Left Nav Option
 * @param styles The styles for the component
 */ export const getUnoLeftNavOptionClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("leftNavOption", styles.root),
        linkContents: mergeStyles("linkContents", styles.linkContents),
        staticIcon: mergeStyles("staticIcon", styles.staticIcon),
        dynamicIcon: mergeStyles("dynamicIcon", styles.dynamicIcon),
        text: mergeStyles("text", styles.text),
        contextMenuButton: mergeStyles("contextMenuButton", styles.contextMenuButton)
    };
});
/**
 * Helper to get custom styles for the Uno leftNav option SimpleBreadcrumb
 * @param theme The current app Fluent v8 theme
 */ export const getUnoLeftNavSimpleBreadcrumbStyles = memoizeFunction((theme)=>{
    const unoLeftNavSimpleBreadcrumbStyles = {
        wrapper: {
            display: "flex",
            alignItems: "center"
        },
        item: {
            color: theme.palette.neutralSecondary,
            fontSize: FontSizes.small
        },
        chevron: {
            color: theme.palette.neutralSecondary
        }
    };
    return unoLeftNavSimpleBreadcrumbStyles;
});
