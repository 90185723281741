// Constants
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
// Models
import { MruApp } from "../service/mru/MruApp";
/**
 * Get the PlanType for the MRU app. Returns null if there is no valid mapping.
 * @param mruApp The MRU app
 */ export function getPlanTypeForMruApp(mruApp) {
    switch(mruApp){
        case MruApp.Project:
            return PlanType.Premium;
        case MruApp.ToDo:
            return PlanType.ToDo;
        case MruApp.Planner:
            return PlanType.Basic;
        default:
            return null;
    }
}
/**
 * Filter that returns true if the MRU item has an app type Portfolio
 * @param mruItem The Mru item
 * @returns True if it's a portfolio/roadmap else false
 */ export function isMruItemAPortfolio(mruItem) {
    return mruItem.app === MruApp.Portfolio;
}
/** Filtering function to segregate plan items from MRU items.
 * @param mruItem The MRU item
 * @returns True if it's a plan, else false.
 */ export function isMruItemAPlan(mruItem) {
    return getPlanTypeForMruApp(mruItem.app) != null;
}
