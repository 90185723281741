import culturesJson from "../../cultures.json";
import stringsJson from "../../view-leftnav-strings.json";
// Utilities
import { retry } from "@ms/uno-utilities/lib/local/RetryUtilities";
export let Strings = stringsJson;
export async function loadTranslations(stringCulture) {
    if (stringCulture.length === 0 || stringCulture === "en-us") {
        return;
    }
    const parsedStringCulture = parseStringCulture(stringCulture);
    if (parsedStringCulture === culturesJson.defaultCulture) {
        return;
    }
    const cultureJson = await retry(()=>import(/* webpackChunkName: "uno-[request]" */ "../../locales/" + parsedStringCulture + "/view-leftnav-strings.json"));
    Strings = cultureJson;
}
/**
 * Parse the culture for loading string files
 * @param culture Culture to use
 */ export const parseStringCulture = (culture)=>{
    if (culture != null) {
        if (culturesJson.cultures.indexOf(culture.toLowerCase()) > -1) {
            return culture.toLowerCase();
        }
        // Retrieve ll piece of ll-cc
        // For example "es-es" => "es", "de-de" => "de"
        const llPrefix = culture.split("-", 1)[0].toLowerCase();
        if (culturesJson.cultures.indexOf(llPrefix) > -1) {
            return llPrefix;
        }
        // Handle cases where the llPrefix maps to a ll-cc string culture
        // For example "en" => "en-us", "zh" => "zh-cn", "pt" => "pt-pt"
        if (culturesJson.fallbacks[llPrefix] != null) {
            return culturesJson.fallbacks[llPrefix];
        }
    }
    return culturesJson.defaultCulture;
};
