// Styles
import { FontSizes, FontWeights, memoizeFunction, concatStyleSets, mergeStyles } from "@fluentui/react";
import { flexParent, textClip } from "../../../utilities/Mixin.styles";
/**
 * Get styles for the SimpleBreadcrumb component
 * @param customStyles Any custom style overrides
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const root = [
        flexParent("row"),
        {
            alignItems: "center"
        }
    ];
    const item = [
        {
            fontSize: FontSizes.size14,
            fontWeight: FontWeights.regular,
            color: theme.semanticColors.bodyText
        }
    ];
    const chevron = [
        {
            display: "inline-block",
            padding: "0px 4px"
        }
    ];
    const wrapper = [
        textClip("ellipsis"),
        {
            width: "100%"
        }
    ];
    return concatStyleSets({
        root,
        item,
        chevron,
        wrapper
    }, customStyles);
});
/**
 * Get the classnames associated with the computed styles for the SimpleBreadcrumb component
 * @param styles The styles for the component
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("simpleBreadcrumb", styles.root),
        item: mergeStyles("item", styles.item),
        chevron: mergeStyles("chevron", styles.chevron),
        wrapper: mergeStyles("wrapper", styles.wrapper)
    };
});
