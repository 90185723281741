// Controls
import { SmartComponent } from "./SmartComponent";
// Constants
import { ViewRenderState } from "@ms/uno-constants/lib/local/ViewConstants";
/**
 * A 'DataFetchingComponent' is a smart component that can fetch data. This class handles the logic of when and what data to fetch.
 *
 * DataFetchingComponent Components define what data they depend on via the FetchData() method, and can optionally specify when this method
 * should be called.
 */ export class DataFetchingComponent extends SmartComponent {
    /**
     * React lifecycle method called once, after initial rendering occurs. At this point, we want to do our initial
     * fetch of data.
     */ componentDidMount() {
        super.componentDidMount();
        // fetch data that this component will need
        this.fetchCriticalData(this.props, this.state);
    }
    /**
     * React lifecycle method called after a component updates. At this point, we may want to re-fetch data.
     * @param prevProps Previous control properties
     * @param prevState Previous control state
     */ componentDidUpdate(prevProps, prevState) {
        // Needs to be reset before calling super.componentDidUpdate as it may mark the component render
        if (this.shouldResetMarkedComponentRender(prevProps, prevState)) {
            this.currentViewRenderState = undefined;
        }
        super.componentDidUpdate(prevProps, prevState);
        if (this.fetchCriticalDataWhenComponentDidUpdate(prevProps, prevState)) {
            this.fetchCriticalData(this.props, this.state);
        }
        if (this.fetchNonCriticalDataWhenComponentDidUpdate(prevProps, prevState) && this.checkForCriticalDataReady()) {
            this.fetchNonCriticalData(this.props, this.state);
        }
    }
    /**
     * Determine whether critical data needs to be re-fetched upon ComponentDidUpdate in the React Lifecycle
     * @param prevProps Previous control properties
     * @param prevState Previous control state
     */ fetchCriticalDataWhenComponentDidUpdate(prevProps, prevState) {
        // default to false. Deriving classes should override this as needed
        return false;
    }
    /**
     * Determine whether non-critical data needs to be re-fetched upon ComponentDidUpdate in the React Lifecycle
     * @param prevProps Previous control properties
     * @param prevState Previous control state
     */ fetchNonCriticalDataWhenComponentDidUpdate(prevProps, prevState) {
        // default to false. Deriving classes should override this as needed
        return false;
    }
    /**
     * Mark the view render state if the fetched data state has changed
     */ tryMarkingComponentRender() {
        // If the ViewRenderState has already saturated to All, return to avoid running `getRenderState` unnecessarily
        if (this.currentViewRenderState === ViewRenderState.All) {
            return;
        }
        this.currentViewRenderState = this.getRenderState();
        this.props.actionCreatorProviders.performanceCollection().viewRender(this.name(), this.currentViewRenderState);
    }
    constructor(props, context){
        super(props, context);
    }
}
