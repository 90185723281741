// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Utilities
import { FontSizes, FontWeights, concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
// Styles
import { flexChildSizeFlexible, flexParent, flexParentCenterAll } from "../../../utilities/Mixin.styles";
import { highContrastBorders } from "../../../utilities/HighContrast.styles";
/** Padded spacing for gap between elements */ const defaultPadding = "12px";
/** Border radius for a rounded icon */ const iconBorderRadius = "4px";
/**
 * Helper to get styles for DynamicIcon
 * @param theme Current app Fluent v8 theme
 * @param customStyles Custom styles to override default DynamicIcon styles
 * @param size Size of the icon
 * @param iconSvgSize Size of the inner icon svg
 */ export const getStyles = memoizeFunction((theme, size, iconSvgSize, customStyles)=>{
    const baseIconStyles = {
        borderRadius: iconBorderRadius,
        width: `${size}px`,
        height: `${size}px`
    };
    const root = [
        flexParent(),
        flexChildSizeFlexible(),
        {
            justifyContent: "space-between",
            padding: `0 ${defaultPadding}`
        }
    ];
    const initialsWrapper = [
        flexParent(),
        flexParentCenterAll,
        highContrastBorders(theme),
        baseIconStyles
    ];
    const initialsText = {
        fontSize: FontSizes.size10,
        fontWeight: FontWeights.semibold,
        color: Colors.White
    };
    const iconStyles = {
        root: [
            highContrastBorders(theme),
            baseIconStyles,
            {
                "> svg": {
                    width: `${iconSvgSize}px`,
                    height: `${iconSvgSize}px`
                }
            }
        ]
    };
    const imageStyles = {
        root: baseIconStyles
    };
    const subComponentStyles = {
        icon: iconStyles,
        image: imageStyles
    };
    const styles = {
        root,
        initialsWrapper,
        initialsText,
        subComponentStyles
    };
    return concatStyleSets(styles, customStyles);
});
export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("dynamic-icon", styles.root),
        initialsWrapper: mergeStyles("initials-wrapper", styles.initialsWrapper),
        initialsText: mergeStyles("initials-text", styles.initialsText)
    }));
