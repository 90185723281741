// Utilities
import { memoizeFunction, mergeStyles, FontSizes, FontWeights } from "@fluentui/react";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
/**
 * Helper to get styles for the Left Nav new plan section button component
 * @param theme Current app Fluent v8 theme
 * @param isSplitButtonEnabled Indicates if the split button is enabled
 * @returns The styles for the component
 */ export const getUnoLeftNavDefaultButtonStyles = memoizeFunction((theme, isSplitButtonEnabled)=>{
    const unoLeftNavDefaultButtonStyles = {
        splitButtonContainer: {
            width: "80%",
            marginTop: "auto",
            marginBottom: "12px",
            marginLeft: "24px",
            marginRight: "24px"
        },
        root: {
            textAlign: "left",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.name === UnoTheme.TeamsContrast ? Colors.White : theme.palette.neutralQuaternary,
            backgroundColor: "transparent",
            "&:active": {
                backgroundColor: theme.semanticColors.buttonBackgroundChecked
            },
            margin: isSplitButtonEnabled ? "" : "12px 24px",
            borderRadius: "5px",
            "&:hover i, &:hover span": {
                color: UnoTheme.TeamsContrast === theme.name ? Colors.Black : theme.palette.themeSecondary
            },
            "> span > div > div": {
                marginTop: "1px",
                ".plannerAdd": {
                    marginTop: "1px"
                }
            }
        },
        splitButtonMenuButton: {
            backgroundColor: "transparent",
            borderLeft: "none",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.name === UnoTheme.TeamsContrast ? Colors.White : theme.palette.neutralQuaternary,
            "&:hover": {
                backgroundColor: theme.name === UnoTheme.TeamsContrast ? theme.semanticColors.buttonBackgroundHovered : theme.palette.neutralLighterAlt
            },
            "&:active": {
                backgroundColor: theme.semanticColors.buttonBackgroundChecked
            },
            borderRadius: "0 5px 5px 0"
        },
        rootHovered: {
            backgroundColor: theme.name === UnoTheme.TeamsContrast ? theme.semanticColors.buttonBackgroundHovered : theme.palette.neutralLighterAlt
        },
        rootFocused: {
            backgroundColor: theme.palette.neutralLighter
        },
        flexContainer: {
            width: "100%",
            marginLeft: isSplitButtonEnabled ? "0px" : "60px"
        }
    };
    return unoLeftNavDefaultButtonStyles;
});
/**
 * Get the classnames associated with the computed styles for Left Nav new plan section
 * @param styles The styles for the component
 */ export const getUnoLeftNavCalloutClassNames = memoizeFunction((styles)=>{
    return {
        calloutContent: mergeStyles("calloutContent", styles.calloutContent),
        calloutTitle: mergeStyles("calloutTitle", styles.calloutTitle),
        calloutDescription: mergeStyles("calloutDescription", styles.calloutDescription),
        calloutLink: mergeStyles("dynamicIcon", styles.calloutLink),
        calloutDismissButton: mergeStyles("calloutDismissButton", styles.calloutDismissButton),
        calloutHeader: mergeStyles("calloutHeader", styles.calloutHeader)
    };
});
/**
 * Get the styles for the Left Nav new plan section
 * @param theme The theme for the component
 */ export const getUnoLeftNavCalloutStyles = memoizeFunction((theme)=>{
    const unoLeftNavNewPlanSectionStyles = {
        calloutContent: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 20px",
            maxWidth: "390px",
            minHeight: "140px"
        },
        calloutTitle: {
            fontWeight: FontWeights.semibold,
            fontSize: FontSizes.size20
        },
        calloutDescription: {
            fontSize: FontSizes.size16,
            padding: "5px"
        },
        calloutLink: {
            color: theme.semanticColors.link,
            fontWeight: FontWeights.regular,
            position: "absolute",
            right: "40px",
            bottom: "30px"
        },
        calloutDismissButton: {
            display: "flex",
            justifyContent: "flex-end"
        },
        calloutHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px"
        }
    };
    return unoLeftNavNewPlanSectionStyles;
});
