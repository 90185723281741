// Styles
import { mergeStyleSets } from "@fluentui/react";
// Utilities
import { flexParent } from "../../../utilities/Mixin.styles";
import { memoizeFunction } from "@fluentui/utilities";
export const getClassNames = memoizeFunction((isSelected = true)=>{
    return mergeStyleSets({
        optionContainer: [
            flexParent(),
            {
                alignItems: "center"
            },
            !isSelected && {
                marginLeft: "26px"
            }
        ]
    });
});
export const getDropDownStyles = memoizeFunction((theme)=>{
    const dropdownStyles = {
        root: {
            position: "relative",
            height: "60px",
            flex: "0 0 auto",
            // Add a bottom shadow
            "::after": {
                background: `linear-gradient(to bottom, ${theme.palette.neutralTertiary} 0%, ${theme.palette.neutralLighter} 50%, transparent)`,
                content: "''",
                display: "block",
                height: "0.5rem",
                opacity: ".2",
                pointerEvents: "none",
                position: "absolute",
                bottom: 0,
                width: "100%",
                zIndex: 2
            }
        },
        dropdown: {
            margin: "14px 28px 0px 4px",
            padding: "0px 10px",
            color: theme.palette.themePrimary,
            ":active, :focus, :hover": {
                backgroundColor: theme.semanticColors.menuItemBackgroundHovered
            }
        },
        title: {
            border: "unset",
            backgroundColor: "transparent",
            color: theme.palette.themePrimary,
            fontWeight: "600"
        }
    };
    return dropdownStyles;
});
export const iconStyles = {
    root: {
        marginRight: "10px"
    }
};
