// Constants
import { Colors, NumGroupColors } from "@ms/uno-constants/lib/local/Colors";
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
//Models
import { ProgressState } from "@ms/uno-models/lib/local/client/legacyPlanner/progressState/ProgressState";
import { PriorityName } from "@ms/uno-models/lib/local/client/legacyPlanner/task/ITask";
import { SupportedReferenceTypes } from "@ms/uno-models/lib/local/client/taskAttachment/ITaskAttachmentType";
import { PortfolioItemStatus } from "@ms/uno-constants/lib/local/PortfolioConstants";
// Utilities
import { hashNumberFromString } from "@ms/uno-utilities/lib/local/StringUtilities";
/**
 * Parse css class name for icon tile from inputString
 * @param inputString String to get css color class for
 */ export function parseIconTileClassname(inputString) {
    const hashed = hashNumberFromString(inputString, NumGroupColors);
    if (hashed < 0) {
        return "";
    }
    return "color" + hashed;
}
/**
 * Get color value based on theme and progress
 * @param theme Planner theme
 * @param progressState Progress
 */ export function getProgressColor(theme, progressState) {
    let notStartedColor;
    let inProgressColor;
    let completedColor;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            notStartedColor = Colors.DarkNotStarted;
            inProgressColor = Colors.DarkInProgress;
            completedColor = Colors.DarkCompleted;
            break;
        case UnoTheme.TeamsLight:
            notStartedColor = Colors.TeamsProgressNotStartedColor;
            inProgressColor = Colors.TeamsProgressInProgressColor;
            // In Teams, "complete" icon color is purple instead of green
            completedColor = Colors.TeamsPurple;
            break;
        case UnoTheme.TeamsDark:
            notStartedColor = Colors.TeamsDarkProgressNotStartedColor;
            inProgressColor = Colors.TeamsDarkProgressInProgressColor;
            // In Teams, "complete" icon color is purple instead of green
            completedColor = Colors.TeamsDarkPurple;
            break;
        case UnoTheme.TeamsContrast:
            // Use empty string to inherit the correct color for high contrast
            notStartedColor = "";
            inProgressColor = "";
            completedColor = "";
            break;
        case UnoTheme.SharePoint:
            notStartedColor = Colors.SharepointTitleFontColor();
            inProgressColor = Colors.SharepointBlueIconColor();
            completedColor = Colors.SharepointCompleteColor();
            break;
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
        default:
            notStartedColor = Colors.NotStarted;
            inProgressColor = Colors.InProgress;
            completedColor = Colors.Completed;
            break;
    }
    let color;
    switch(progressState){
        case ProgressState.InProgress:
            color = inProgressColor;
            break;
        case ProgressState.Complete:
            color = completedColor;
            break;
        case ProgressState.NotStarted:
        default:
            color = notStartedColor;
            break;
    }
    return color;
}
/**
 * Get color value based on theme and priority
 * @param theme Planner theme
 * @param priorityName Priority
 */ export function getPriorityColor(theme, priorityName) {
    let urgentColor;
    let importantColor;
    let mediumColor;
    let lowColor;
    switch(theme.name){
        case UnoTheme.Dark:
        case UnoTheme.LoopDark:
            urgentColor = Colors.DarkUrgent;
            importantColor = Colors.DarkImportant;
            mediumColor = Colors.DarkMedium;
            lowColor = Colors.DarkLow;
            break;
        case UnoTheme.TeamsLight:
            urgentColor = Colors.TeamsPriorityUrgentColor;
            importantColor = Colors.TeamsPriorityImportantColor;
            mediumColor = Colors.TeamsPriorityMediumColor;
            lowColor = Colors.TeamsPriorityLowColor;
            break;
        case UnoTheme.TeamsDark:
            urgentColor = Colors.TeamsDarkPriorityUrgentColor;
            importantColor = Colors.TeamsDarkPriorityImportantColor;
            mediumColor = Colors.TeamsDarkPriorityMediumColor;
            lowColor = Colors.TeamsDarkPriorityLowColor;
            break;
        case UnoTheme.TeamsContrast:
            // Use empty string to inherit the correct color for high contrast
            urgentColor = "";
            importantColor = "";
            mediumColor = "";
            lowColor = "";
            break;
        case UnoTheme.SharePoint:
            urgentColor = Colors.SharepointHighPriIconColor();
            importantColor = Colors.SharepointHighPriIconColor();
            mediumColor = Colors.SharepointCompleteColor();
            lowColor = Colors.SharepointBlueIconColor();
            break;
        case UnoTheme.Light:
        case UnoTheme.LoopLight:
        default:
            urgentColor = Colors.Urgent;
            importantColor = Colors.Important;
            mediumColor = Colors.Medium;
            lowColor = Colors.Low;
            break;
    }
    let color;
    switch(priorityName){
        case PriorityName.Urgent:
            color = urgentColor;
            break;
        case PriorityName.Important:
            color = importantColor;
            break;
        case PriorityName.Low:
            color = lowColor;
            break;
        case PriorityName.Medium:
        default:
            color = mediumColor;
            break;
    }
    return color;
}
/**
 * Get color value of the portfolio status
 * @param theme Planner theme
 * @param status Status
 */ export function getPortfolioItemStatusColor(theme, status) {
    const statusOptionLightThemeColor = {
        [PortfolioItemStatus.Default]: Colors.PortfolioItemStatusDefault,
        [PortfolioItemStatus.NotStarted]: Colors.PortfolioItemStatusNotStarted,
        [PortfolioItemStatus.OnTrack]: Colors.PortfolioItemStatusOnTrack,
        [PortfolioItemStatus.AtRisk]: Colors.PortfolioItemStatusAtRisk,
        [PortfolioItemStatus.OffTrack]: Colors.PortfolioItemStatusOffTrack,
        [PortfolioItemStatus.Closed]: Colors.PortfolioItemStatusClosed
    };
    const statusOptionDarkThemeColor = {
        [PortfolioItemStatus.Default]: Colors.DarkPortfolioItemStatusDefault,
        [PortfolioItemStatus.NotStarted]: Colors.DarkPortfolioItemStatusNotStarted,
        [PortfolioItemStatus.OnTrack]: Colors.DarkPortfolioItemStatusOnTrack,
        [PortfolioItemStatus.AtRisk]: Colors.DarkPortfolioItemStatusAtRisk,
        [PortfolioItemStatus.OffTrack]: Colors.DarkPortfolioItemStatusOffTrack,
        [PortfolioItemStatus.Closed]: Colors.DarkPortfolioItemStatusClosed
    };
    const statusOptionContrastThemeColor = {
        [PortfolioItemStatus.Default]: Colors.ContrastPortfolioItemStatusDefault,
        [PortfolioItemStatus.NotStarted]: Colors.ContrastPortfolioItemStatusNotStarted,
        [PortfolioItemStatus.OnTrack]: Colors.ContrastPortfolioItemStatusOnTrack,
        [PortfolioItemStatus.AtRisk]: Colors.ContrastPortfolioItemStatusAtRisk,
        [PortfolioItemStatus.OffTrack]: Colors.ContrastPortfolioItemStatusOffTrack,
        [PortfolioItemStatus.Closed]: Colors.ContrastPortfolioItemStatusClosed
    };
    let color;
    switch(theme.name){
        case UnoTheme.TeamsContrast:
            color = statusOptionContrastThemeColor[status];
            break;
        case UnoTheme.LoopWorkspaceDark:
        case UnoTheme.LoopDark:
        case UnoTheme.TeamsDark:
        case UnoTheme.Dark:
            color = statusOptionDarkThemeColor[status];
            break;
        default:
            color = statusOptionLightThemeColor[status];
            break;
    }
    return color;
}
/**
 * Get color class name based on document type
 * @param docType File type, i.e Word/Excel/PowerPoint
 */ export function getDocumentColorClassName(docType) {
    let colorName;
    switch(docType){
        case SupportedReferenceTypes.Word:
            colorName = "wordColor";
            break;
        case SupportedReferenceTypes.Excel:
            colorName = "excelColor";
            break;
        case SupportedReferenceTypes.PowerPoint:
            colorName = "powerPointColor";
            break;
        default:
            colorName = "";
            break;
    }
    return colorName;
}
