// Styles
import { flexParent, flexChildSizeFixed, styleButtonAsDiv } from "@ms/uno-controls/lib/local/utilities/Mixin.styles";
// Utilities
import { memoizeFunction, mergeStyles, FontWeights, FontSizes, Depths } from "@fluentui/react";
// Constants
import { UnoLeftNavBoxShadow, UnoLeftNavMaxWidth, UnoLeftNavMinWidth, UnoLeftNavWidth } from "@ms/uno-controls/lib/local/constants/StyleConstants";
/**
 * Left Nav Style constants
 */ export const rowIconTextSpacing = "8px";
export const rowEdgeToContentSpacing = "2px";
const optionsListTopPadding = "11px";
/**
 * Helper to get styles for the Uno Left Nav
 * @param leftNavCollapsed Whether the left nav is collapsed
 * @param theme Current app Fluent v8 theme
 * @param rtl Whether the app is in RTL mode
 * @param showAppInfo Whether to render the app info in app header section
 */ export const getUnoLeftNavStyles = memoizeFunction((isLeftNavCollapsed, theme, rtl, showAppInfo = true)=>{
    const unoLeftNavStyles = {
        root: [
            flexParent("column"),
            {
                width: UnoLeftNavWidth,
                maxWidth: isLeftNavCollapsed ? UnoLeftNavMinWidth : UnoLeftNavMaxWidth,
                backgroundColor: theme.semanticColors.bodyBackground,
                boxShadow: UnoLeftNavBoxShadow
            },
            isLeftNavCollapsed && {
                boxShadow: "none",
                backgroundColor: theme.semanticColors.bodyStandoutBackground
            },
            !isLeftNavCollapsed && {
                borderRight: `1px solid ${theme.semanticColors.bodyDivider}`
            }
        ],
        appHeader: [
            flexParent("row"),
            flexChildSizeFixed("28px"),
            {
                height: "28px",
                gap: "10px",
                padding: isLeftNavCollapsed ? "13px 8px 17px 18px" : "13px 18px 13px 18px",
                "> .plannerPanelLeftContract": [
                    {
                        cursor: "pointer",
                        marginLeft: showAppInfo ? "auto" : "2px",
                        marginRight: showAppInfo ? "2px" : "auto",
                        color: theme.palette.themePrimary
                    },
                    rtl && {
                        transform: "scaleX(-1)"
                    }
                ],
                "> .plannerPanelRightContract": [
                    {
                        cursor: "pointer",
                        marginLeft: showAppInfo ? "2px" : "auto",
                        marginRight: showAppInfo ? "auto" : "2px",
                        color: theme.palette.themePrimary
                    },
                    rtl && {
                        transform: "scaleX(-1)"
                    }
                ],
                boxSizing: "content-box"
            },
            isLeftNavCollapsed && {
                boxShadow: Depths.depth4,
                borderRightStyle: "hidden"
            }
        ],
        appHeaderTitle: {
            fontSize: FontSizes.size18,
            fontWeight: FontWeights.bold,
            lineHeight: "28px",
            margin: "0px",
            verticalAlign: "center"
        },
        pinnedSectionHeader: [
            flexChildSizeFixed("24px"),
            flexParent("column"),
            {
                overflow: "hidden",
                padding: `${optionsListTopPadding} 0px`,
                marginInlineStart: "20px",
                fontSize: FontSizes.size12,
                fontStyle: "normal",
                fontWeight: FontWeights.regular,
                justifyContent: "center"
            }
        ],
        leftNavContent: [
            flexParent("column"),
            {
                overflowY: "hidden",
                "&:hover": {
                    overflowY: "auto"
                },
                "> div:first-child": {
                    marginTop: "0px"
                }
            }
        ],
        leftNavOptionsList: [
            flexChildSizeFixed(),
            flexParent("column"),
            {
                "&.list": [
                    {
                        padding: `${optionsListTopPadding} 0px 0px`
                    }
                ],
                "&.staticSection": [
                    {
                        marginBottom: "16px"
                    }
                ],
                "&.newPlanSection": [
                    {
                        position: "sticky",
                        marginTop: "auto"
                    }
                ],
                marginBlockStart: "0px",
                marginBlockEnd: "0px",
                listStyleType: "none"
            }
        ],
        emptyPinnedSectionContent: [
            flexChildSizeFixed("36px"),
            flexParent("column"),
            {
                color: theme.palette.neutralSecondary,
                overflow: "hidden",
                marginInlineStart: "20px",
                marginBlockStart: "5px",
                fontSize: FontSizes.size12,
                fontWeight: FontWeights.regular,
                justifyContent: "center"
            }
        ],
        errorPinnedSectionContent: [
            flexChildSizeFixed(),
            flexParent("column"),
            {
                margin: "5px 20px",
                fontSize: FontSizes.size12,
                fontWeight: FontWeights.regular,
                "> .errorMessage": [
                    flexChildSizeFixed(),
                    flexParent("row"),
                    {
                        color: theme.semanticColors.errorText,
                        marginBottom: "8px",
                        "> i": {
                            marginRight: "6px"
                        }
                    }
                ],
                "> .retryButton": [
                    flexChildSizeFixed(),
                    styleButtonAsDiv,
                    {
                        color: theme.palette.themePrimary,
                        marginLeft: "22px",
                        cursor: "pointer"
                    }
                ]
            }
        ]
    };
    return unoLeftNavStyles;
});
/**
 * Get the classnames associated with the computed styles for the Uno Left Nav component
 * @param styles The styles for the component
 */ export const getUnoLeftNavClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("sideNav", styles.root),
        appHeader: mergeStyles("appHeader", styles.appHeader),
        appHeaderTitle: mergeStyles("appHeaderTitle", styles.appHeaderTitle),
        leftNavContent: mergeStyles("leftNavContent", styles.leftNavContent),
        leftNavOptionsList: mergeStyles("leftNavOptionsList", styles.leftNavOptionsList),
        pinnedSectionHeader: mergeStyles("pinnedSectionHeader", styles.pinnedSectionHeader),
        emptyPinnedSectionContent: mergeStyles("emptyPinnedSectionContent", styles.emptyPinnedSectionContent),
        errorPinnedSectionContent: mergeStyles("errorPinnedSectionContent", styles.errorPinnedSectionContent)
    };
});
