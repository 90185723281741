// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
const gradientColor1 = "#B377E0";
const gradientColor2 = "#5166D7";
/**
 * Styles for the DynamicIcon in PlanIcon control
 */ export const PlanDynamicIconStyles = {
    subComponentStyles: {
        icon: {
            root: {
                background: `linear-gradient(224deg, ${gradientColor1} 1.02%, ${gradientColor2} 123.98%)`,
                color: Colors.White,
                ":hover": {
                    color: Colors.White
                }
            }
        },
        image: {}
    }
};
