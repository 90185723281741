// Utilities
import map from "lodash/map";
import some from "lodash/some";
import * as React from "react";
// Constants
import { KeyCode } from "@ms/uno-constants/lib/local/KeyCode";
export var KeyboardUtilities;
(function(KeyboardUtilities) {
    function keyCodeIsSelectionKey(keyCode, keycodeMapper) {
        let selectionKeys = [
            KeyCode.ENTER,
            KeyCode.SPACE
        ];
        if (keycodeMapper) {
            selectionKeys = map(selectionKeys, keycodeMapper);
        }
        return keyCodeInSet(keyCode, selectionKeys);
    }
    /**
     * Check if key code is a valid selection key
     * @param {KeyCode} keycode The keyboard code to be evaluated
     * @param {(keyCode: number) => number} [keycodeMapper] keycode mapper - e.g., "ENTER" key -> "End" key
     */ KeyboardUtilities.keyCodeIsSelectionKey = keyCodeIsSelectionKey;
    function executeWhenAllowedKeyPressed(allowedKeyCodes) {
        return (action)=>{
            return (ev, ...args)=>{
                if (ev) {
                    if (keyCodeInSet(ev.keyCode, allowedKeyCodes)) {
                        action(...args);
                        return true;
                    }
                }
                return false;
            };
        };
    }
    /**
     * Execute action if keyboard event is one of the allowed keycodes
     * @param {KeyCode[]} allowedKeyCodes The keycode(s) allowed to invoke action
     * @param {(...args: any[]) => void} action The action to invoke if key pressed was in key code set
     * @param {React.KeyboardEvent} ev The keyboard event that was triggered
     * @param {any[]} args Arguments to be used when invoking action
     * @return {boolean} Returns true if action was called, false otherwise
     */ KeyboardUtilities.executeWhenAllowedKeyPressed = executeWhenAllowedKeyPressed;
    function keyCodeInSet(keyPressed, allowedKeyCodes) {
        return some(allowedKeyCodes, (keyCode)=>{
            return keyCode === keyPressed;
        });
    }
    /**
     * Return true if keycode is in the set of allowed keycodes
     * @param {KeyCode} keyPressed The key code of the key pressed
     * @param {KeyCode[]} allowedKeyCodes The allowed keycode(s)
     */ KeyboardUtilities.keyCodeInSet = keyCodeInSet;
    function getKey(event) {
        if ("keyCode" in event && event.keyCode != null && event.keyCode !== 0) {
            return event.keyCode;
        }
        if ("charCode" in event && event.charCode != null && event.charCode !== 0) {
            return event.charCode;
        }
        if ("which" in event && event.which != null && event.which !== 0) {
            return event.which;
        }
        return 0;
    }
    /**
     * Helper function to get the key of a keyboard or mouse event
     * @param event The UI event to get the key of
     */ KeyboardUtilities.getKey = getKey;
    function isButtonPress(event) {
        const keyPressed = getKey(event);
        return keyPressed === KeyCode.ENTER || keyPressed === KeyCode.SPACE || event.type === "click";
    }
    /**
     * Helper function to test if a UI event on a button-like element should be considered a select event
     * Warning: Not to be used with input elements, such as TextFields
     * @param event The UI event on the button-like element
     */ KeyboardUtilities.isButtonPress = isButtonPress;
})(KeyboardUtilities || (KeyboardUtilities = {}));
